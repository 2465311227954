








































import { Component, Prop, Vue } from 'vue-property-decorator'
import { waitTimer } from '@/core/pendings'
import { RouteNames } from '@/router'
import { settingsModule } from '@/store/settings'
import { BButton, BSpinner } from 'bootstrap-vue'
import { round } from 'lodash-es'
import { logBreadcrumb } from '@/core/logger'
import { InventoryActionTypes } from '@/graphql/default/graphql.schema'
import { cart } from '@/store/cart/cartModule'

const TAG = 'Inventory.vue'

const log = (...arg: any[]) =>
  logBreadcrumb({ tag: TAG, color: 'green' }, ...arg)

@Component({
  components: {
    BSpinner,
    BButton
  },
  filters: {
    round
  }
})
export default class Payment extends Vue {
  readonly RouteNames = RouteNames;
  private error = false;
  private result: string | null = null;

  @Prop({ type: String })
  action!: InventoryActionTypes;

  timer = 0;

  created() {
    this.inventoryCart()
  }

  get actionText() {
    if (this.action === InventoryActionTypes.ReturnToStock) {
      return 'Идет процесс списания товаров'
    } else if (this.action === InventoryActionTypes.ExpiredInTerminal) {
      return 'Идет процесс списания товаров'
    } else if (this.action === InventoryActionTypes.Load) {
      return 'Идет процесс загрузки товаров'
    } else {
      return ''
    }
  }

  async inventoryCart() {
    log('процесс списания товаров')
    this.error = false
    try {
      await cart.inventoryCart(this.action)
      if (this.action === InventoryActionTypes.ReturnToStock || this.action === InventoryActionTypes.ExpiredInTerminal) {
        this.result = 'Товары успешно списаны'
      } else if (this.action === InventoryActionTypes.Load) {
        this.result = 'Товары успешно загружены'
      } else {
        return ''
      }
    } catch (error) {
      if (error.message) {
        this.error = true
        this.result = error.message.includes('|') ? error.message.split('|')[1] : error.message
      }
    }

    this.timer = settingsModule.state.publicSettings.receiptDisplayTime
    waitTimer(
      settingsModule.state.publicSettings.receiptDisplayTime,
      1e3,
      () => {
        this.timer -= 1e3
        return this.$route.name !== RouteNames.inventory
      }
    ).catch(() => {
      this.$router.push({
        name: RouteNames.index
      })
    })
  }
}
